// tslint:disable
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * NutritionalValue_CAP entity interface.
 */
export interface INutritionalValue_CAP {
    DataAreaId?: string;
    TableNumber: number;
    SortNumber: number;
    Text: number;
    TableField?: string;
    Indentation: number;
    CharDimension: number;
    Bold: number;
    Italics: number;
    Id: number;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * NutritionalValue_CAP entity class.
 */
export class NutritionalValue_CAPExtensionClass implements INutritionalValue_CAP {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DataAreaId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TableNumber: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public SortNumber: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Text: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public TableField: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Indentation: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CharDimension: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Bold: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Italics: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Id: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DataAreaId = odataObject.DataAreaId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TableNumber = odataObject.TableNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.SortNumber = odataObject.SortNumber;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Text = odataObject.Text;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TableField = odataObject.TableField;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Indentation = odataObject.Indentation;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CharDimension = odataObject.CharDimension;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Bold = odataObject.Bold;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Italics = odataObject.Italics;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Id = odataObject.Id ? parseInt(odataObject.Id, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}
